import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

export default function Contact() {
    return(
        <Layout>
            <div className="max-w-xl mx-auto">
                <h1 className="mb-8">Contact</h1>
                <p className="mb-16">Please use this form to contact us or email us directly at <Link href="mailto:finitywastaken@gmail.com" className="link"><i>finitywastaken@gmail.com</i></Link>.</p>
                <section className="mb-6">
                    <form className="formx flex flex-col gap-6">
                        <label>
                            <span>Email</span>
                            <input className="px-2 w-3/4 py-1 border-2 focus:outline-none focus:border-purple-700 rounded-md transition ease-in" type="email" />
                        </label>
                        <label>
                            <span>Subject</span>
                            <input className="px-2 w-3/4 py-1 border-2 focus:outline-none focus:border-purple-700 rounded-md transition ease-in" type="text" />
                        </label>
                        <label>
                            <span>Body</span>
                            <textarea rows={6} className="px-2 w-3/4 py-1 border-2 focus:outline-none focus:border-purple-700 rounded-md transition ease-in"></textarea>
                        </label>
                        <button className="md:self-end download-btn" type="submit">Submit</button>
                    </form>
                </section>
                <section className="text-center mb-10">
                    <StaticImage src="../assets/images/cinemahd-logo.webp" alt="Cinema HD Contact" />
                    <h2 className="text-purple-700">Cinema HD</h2>
                </section>
            </div>
        </Layout>
    ) 
}
